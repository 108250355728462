<template>
  <div class="row mx-0 justify-content-center" >
    <div class="col-12">
      <div class="row mx-0 justify-content-center">
        <div class="col-12 mb-3 text-center font19" >
          Bank Details
        </div>
        <NewBankDetails v-if="!user || !latestDebitOrder || isNewBank" @useExistingBank="isNewBank = false" v-model="value.donorBankAccount" />
        <ExistingBankDetails v-else @useNewBank="isNewBank = true" v-model="value.donorBankAccount" />
      </div>
      <div class="row mx-0 justify-content-center">
        <div class="col-10 my-4 text-center divider">
        </div>
      </div>
      <PaymentDetails v-model:startDate="value.startDate" v-model:debitOrderDay="value.debitOrderDay" v-model:frequency="value.frequency" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    NewBankDetails: defineAsyncComponent(() => import('./views/NewBankDetails.vue')),
    PaymentDetails: defineAsyncComponent(() => import('./views/PaymentDetails.vue')),
    ExistingBankDetails: defineAsyncComponent(() => import('./views/ExistingBankDetails.vue'))
  },
  name: 'DebitOrder',
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      isNewBank: false
    }
  },
  computed: {
    ...mapGetters([
      'user', 'debitOrders'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    },
    latestDebitOrder () {
      if (this.debitOrders && this.debitOrders.length > 0) {
        return this.debitOrders[0]
      } else {
        return null
      }
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
